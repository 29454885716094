<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add Payment Method</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Payment Method Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{ errors.first('name') }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
// Store Module
import modulePaymentMethodManagement from "@/store/payment-method-management/modulePaymentMethodManagement.js";

export default {
  name: "PaymentMethodAdd",

  metaInfo: {
    title: "Add Payment Method",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  data: () => ({
    payload: {
      name: ""
    }
  }),

  methods: {
    storeData() {
      const formData = new FormData();
      // set formData
      formData.set("name", this.payload.name);

      this.$store
        .dispatch("paymentMethodManagement/storePaymentMethod", formData)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/payment-methods");
        })
        .catch(err => {
          this.$catchErrorResponse(err)
        });
    }
  },

  created() {
    if (!modulePaymentMethodManagement.isRegistered) {
      this.$store.registerModule("paymentMethodManagement", modulePaymentMethodManagement);
      modulePaymentMethodManagement.isRegistered = true;
    }
  }
};
</script>

<style></style>
