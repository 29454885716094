import axios from '@/axios.js'

export default {
  fetchPaymentMethods ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('payment-method')
        .then(({data: response}) => {
          commit('SET_PAYMENT_METHODS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchPaymentMethod (context, paymentMethodID) {
    return new Promise((resolve, reject) => {
      axios.get(`payment-method/${paymentMethodID}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storePaymentMethod (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('payment-method', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updatePaymentMethod (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`payment-method/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyPaymentMethod ({ commit }, paymentMethodID) {
    return new Promise((resolve, reject) => {
      axios.delete(`payment-method/${paymentMethodID}`)
        .then((response) => {
          commit('REMOVE_RECORD', paymentMethodID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
