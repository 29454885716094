import state from './modulePaymentMethodManagementState.js'
import mutations from './modulePaymentMethodManagementMutations.js'
import actions from './modulePaymentMethodManagementActions.js'
import getters from './modulePaymentMethodManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

